/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* font-family */

@import "https://fonts.googleapis.com/css?family=Poppins";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
//swiper (ion-slides)
@import "~swiper/scss";
@import "~swiper/css/navigation";
@import "swiper/css/pagination";
@import "~@ionic/angular/css/ionic-swiper";

@import '/src/colors.scss';

ion-button,
ion-card,
ion-chip,
ion-fab,
.swiper-button-next,
.swiper-button-prev {
  box-shadow: rgb(0 0 0 / 10%) -8px 8px 8px;
  // border-radius: 8px;
  // margin:8px;
}

.iti__country-list {
  z-index: 3 !important;
}

.ion-page {
  justify-content: flex-start !important;
}
.theme-color{
  background-color: var(--ion-color-theme);
}
.bg-light{
  background-color: #fff;
}
.secondary-theme-color{
  background-color: var(--secondary-theme-color);
}
.border-primary {
  border: 1px solid var(--ion-color-primary);
}

body{
  background-color: var(--ion-color-theme);
}
ion-button {
  border-radius: 8px;
  color: #000;
  text-transform: capitalize;
}

ion-fab {
  border-radius: 50% 50%;
}

.text-dark {
  color: #332d2d !important;
}

.text-light {
  color: #fbfbfb;
}

.w-95 {
  width: 95%;
}

.mt-2 {
  margin-top: 1rem;
}

.ion-color-light {
  // --ion-color-base: var(--ion-color-light, #f4f5f8) !important;
  // --ion-color-base-rgb: var(--ion-color-light-rgb, 244, 245, 248) !important;
  // --ion-color-contrast: var(--ion-color-light-contrast, #000) !important;
  // --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb, 0, 0, 0) !important;
  // --ion-color-shade: var(--ion-color-light-shade, #d7d8da) !important;
  // --ion-color-tint: var(--ion-color-light-tint, #f5f6f9) !important;
  --ion-color-base: #ffffff !important;
  --ion-color-base-rgb: #ffffff !important;
  --ion-color-contrast: var(--ion-color-light-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb,
      0,
      0,
      0) !important;
  --ion-color-shade: #ffffff !important;
  --ion-color-tint: #ffffff !important;
}

b,
strong {
  font-weight: 500;
}

.text-align {
  text-align: center;
}

.font-weight-normal {
  font-weight: 300;
}

// h1,
// h2,
// h3,
// h4,
// h5 {
//     // text-transform: lowercase;
// }

h1 {
  font-size: 80px;
  font-weight: normal;
}

h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: lowercase;
}

p {
  font-size: 14px;
  line-height: 17px;
}

ion-card {
  border-radius: 12px;
  box-shadow: 3px 3px 10px rgba(var(--ion-color-primary-rgb), 0.5);
  border: 1px solid #ededed;
  text-align: left;
}

ion-icon {
  border-radius: 25px;
  padding: 4px 4px;
}

ion-select {
  border: 1px solid #ededed;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  // padding: 5px 15px;
  margin: 0px 5px;
  width: 86px;
}

ion-item {
  --inner-border-width: 0px 0px 0px 0px;
}

.back-arrow {
  color: #000;
  border-radius: 25px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.06);
  padding: 10px 11px;
  border: 1px solid #ededed;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  /* font-size: 20px; */
}

ion-label {
  font-size: 12px !important;
  line-height: 25px;
}

// ion-badge {
// 	position: relative;
// 	z-index: 1000000;
// 	right: -33px;
// 	top: -22px;
// }
ion-header {
  background: var(--ion-color-theme);
}

ion-footer {
  background: var(--ion-color-theme);
}

.sc-ion-popover-ios-h {
  --width: 92px;
  --max-height: 90%;
  --box-shadow: none;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.08);
}

.card-content-ios p {
  margin-bottom: 10px !important;
  font-size: 14px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 25px !important;
  font-weight: bold;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: #ffffff;
  border-radius: 50%;
  margin-top: 40px;
  padding: 30px;
}

.swiper-button-next {
  color: #ff0000;
}

.swiper-button-prev {
  color: #000;
}

// .login-btn {
// 	font-size: 20px;
// 	text-align: left;
// 	color: #212121;
// 	--background: var(--ion-color-white);
// 	position: absolute;
// 	width: 160px;
// 	height: 100px;
// 	background: var(--ion-color-white);
// 	box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.06);
// 	border-radius: 20px;
// 	line-height: 130px;
// 	padding: 0px 25px;
// }
.arrow i {
  box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.06);
  border-radius: 50px;
  width: 45px;
  height: 45px;
  text-align: center;
  vertical-align: middle;
  color: var(--ion-color-black);
  cursor: pointer;
  padding: 11px 7px;
  font-size: 20px;
}

.social-icon {
  width: 32px;
}

.title-email {
  position: relative;
  top: 1px;
}

.title-facebook {
  position: relative;
  top: 4px;
}

.title-mobile {
  position: relative;
  top: 2px;
}

.logo-grid {
  margin-top: 0;
  top: -5%;
}

.logo {
  display: flex;
  justify-content: center;
}

.body-grid {
  max-height: auto;
  background-color: var(--ion-color-white);
  top: 0%;
  width: 100%;
}

.social-icon {
  width: 32px;
}

.font-weight-normal {
  font-weight: 300 !important;
}

#fname {
  border-radius: 50px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  font-size: 14px;
}

.radiobtn {
  position: relative;
  display: block;

  label {
    display: block;
    color: var(--ion-color-black);
    border-radius: 50px;
    padding: 13px 20px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    margin-bottom: 5px;
    cursor: pointer;
    font-weight: 300;
    font-size: 14px;
  }

  &:before {
    background: transparent;
    transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
      0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
    z-index: 2;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: center;
    width: 0;
    height: 0;
  }
}

input[type="radio"] {
  display: none;
  position: absolute;
  width: 100%;
  appearance: none;

  &:checked+label {
    background: var(--ion-color-white-dark);
    animation-name: blink;
    animation-duration: 1s;
    border: 1px solid var(--ion-color-white-light);
  }
}

.weight-k {
  font-size: 25px;
}

.sign-up-by-button {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  text-align: left;
  border-radius: 25px;
  padding: 8px 20px;
  vertical-align: middle;
  display: inline-flex;
  font-size: 15px;
  line-height: 28px;
}

.date-input {
  border-radius: 25px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  font-size: 14px;
}

.lets-begain-button {
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.06);
  text-align: left;
  border-radius: 25px;
  padding: 8px 25px;
  vertical-align: middle;
  display: inline-flex;
  font-size: 15px;
}

.header-md::after {
  background-image: none;
}

.footer-md::before {
  background-image: none;
}

#c-weight {
  border-radius: 50px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  font-size: 14px;
}

.switch-button {
  height: 25px;
}

.switch-button-span {
  position: relative;
  top: -7px;
  margin: 0 7px;
  opacity: 0.4;
  color: var(--ion-color-black);
}

.slider {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 1.5em;
  background: transparent;
  font: 1em/1 arial, sans-serif;
  border: none;
}

.slider::-webkit-range-thumb {
  margin-top: -0.55em;
  box-sizing: border-box;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: url("/assets/weight-arrow.png");
}

.slider::-moz-range-thumb {
  box-sizing: border-box;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: url("/assets/weight-arrow.png");
}

.slider::-ms-range-thumb {
  margin-top: 0;
  box-sizing: border-box;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: url("/assets/weight-arrow.png");
}

.slider::-ms-tooltip {
  display: none;
}

#tickmarks {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

#tickmarks p {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 3px;
  background: var(--ion-color-white-light);
  height: 16px;
  line-height: 75px;
  margin: 0 0 20px 0;
}

#tickmarks .full-point {
  background: var(--ion-color-primary);
  height: 64px;
  line-height: 160px;
}

#tickmarks .half-point {
  height: 32px;
}

.radio-btn {
  position: relative;
  display: block;

  label {
    display: block;
    color: var(--ion-color-black);
    border-radius: 50px;
    padding: 21px 20px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    margin-bottom: 5px;
    cursor: pointer;
    font-weight: 300;
    font-size: 14px;
  }

  &:before {
    background: transparent;
    transition: 0.1s width cubic-bezier(0.075, 0.82, 0.165, 1) 0s,
      0.3s height cubic-bezier(0.075, 0.82, 0.165, 2) 0.1s;
    z-index: 2;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: center;
    width: 0;
    height: 0;
  }
}

.searchbar-input.sc-ion-searchbar-md {
  border-radius: 50px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
}

.search-btn {
  display: block;
  color: var(--ion-color-black);
  border-radius: 50px;
  padding: 21px 20px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  margin-bottom: 5px;
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
}

.select-remove {
  position: absolute;
  right: 27px;
  top: 3px;
  font-size: 18px;
}

.checked {
  color: var(--ion-color-red);
  padding: 0 2px;
}

.rating-btn label {
  display: block;
  color: var(--ion-color-black);
  border-radius: 50px;
  padding: 13px 20px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
  margin-bottom: 5px;
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
}

// ion-content {
//   --offset-bottom: auto!important;
//   --overflow: hidden;
//   overflow: auto;
//   &::-webkit-scrollbar {
//     display: none;
//   }
// }
.buy-btn {
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #212121;
  position: relative;
  width: 133px;
  height: 42px;
  background: var(--ion-color-white);
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 10px 0;
  display: inline-block;
}

.d-value {
  background: var(--ion-color-white);
  border: 1px solid #ededed;
  box-sizing: border-box;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 8px 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
}

.title-p {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
}

.icon-ion {
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.06);
  border-radius: 50px;
  padding: 6px 6px;
}

.common-box {
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.06);
  border-radius: 24px;
  background: var(--ion-color-white);
  border: 1px solid #ededed;
}

.common-box p {
  font-size: 14px;
  line-height: 17px;
  color: #212121;
}

.ion-tabs {
  position: relative !important;
}

.forget-password-p {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #212121;
  opacity: 0.4;
}

.custom-modal .modal-wrapper {
  --height: 40%;
  --width: 90%;
  --background: var(--ion-color-white);
  --border-radius: 20px;
  --padding: 50px;
  --overflow: hidden;
}

.v-modal .modal-wrapper {
  --height: 19%;
  --width: 90%;
  --background: var(--ion-color-white);
  --border-radius: 20px;
  --padding: 50px;
  --overflow: hidden;
}

.otp-input input {
  padding-right: 0px !important;
}

.swiper-pagination-bullet {
  background: var(--ion-color-primary);
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal>.swiper-pagination-bullets {
  bottom: 7px;
}

.text-lowercase {
  text-transform: lowercase;
}

.card-dots {
  margin-bottom: 0px !important;
  font-size: 29px !important;
  position: absolute;
  right: 21px;
  top: 0px;
}

.box-shadow-none {
  box-shadow: none !important;
  border: none;
}

.size-large ion-label {
  font-size: 22px;
}

.my-cart-btn {
  width: 260px;
}

.logo-s {
  background: #ffffff;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.06);
  border-radius: 50px;
  width: 53px;
  height: 53px;
  display: inline-block;
  padding: 13px 9px;
}

.alert-title.sc-ion-alert-md {
  color: var(--ion-color-primary);
  font-size: 20px;
  font-weight: 500;
}

span {
  color: var(--ion-color-primary);
  // margin-left: 8px;
}

ion-title {
  margin-top: 25px;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
}

.back-arrow-btn {
  color: #000;
  border-radius: 25px;
  box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.06);
  padding: 10px 11px;
  margin-top: 30px;
  margin-left: 10px;
  border: 1px solid #ededed;
  width: 20px;
  height: 20px;
  cursor: pointer;
  // font-size: 20px;
}

/* Added by Gaurav*/

ion-button,
ion-card,
ion-chip,
ion-fab {
  box-shadow: rgb(0 0 0 / 10%) -8px 8px 8px;
  // border-radius: 8px;
  // margin:8px;
}

@media (min-width: 1100px) {
  .app-body {
    width: 30%;
    position: absolute;
    right: 1%;
    margin: 0 9% 0 10%;
    overflow-y: scroll;
    border: 1px solid #ededed;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    // background-position: 100%;
    // background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .app-body {
    width: 100%;
  }
}

@media only screen and (orientation: portrait) {
  .screen-body-grid {
    position: relative;
    top: 34%;
  }
}

@media only screen and (orientation: landscape) {
  .screen-body-grid {
    position: relative;
    top: 14%;
  }
}

ion-button {
  // border-radius: 8px;
  border-radius: 25px;
  // box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.06);
  // color:#212121;
  // font-size: 15px;
  // padding: 0px 35px;
}

ion-fab {
  border-radius: 50% 50%;
}

.ion-color-light {
  // --ion-color-base: var(--ion-color-light, #f4f5f8) !important;
  // --ion-color-base-rgb: var(--ion-color-light-rgb, 244, 245, 248) !important;
  // --ion-color-contrast: var(--ion-color-light-contrast, #000) !important;
  // --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb, 0, 0, 0) !important;
  // --ion-color-shade: var(--ion-color-light-shade, #d7d8da) !important;
  // --ion-color-tint: var(--ion-color-light-tint, #f5f6f9) !important;
  --ion-color-base: #ffffff !important;
  --ion-color-base-rgb: #ffffff !important;
  --ion-color-contrast: var(--ion-color-light-contrast, #000) !important;
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb,
      0,
      0,
      0) !important;
  --ion-color-shade: #ffffff !important;
  --ion-color-tint: #ffffff !important;
}

.ion-fab-nav {
  margin-bottom: 20%;
}

a {
  text-decoration: none;
  color: #000;
}

[aria-checked="true"].sc-ion-alert-ios .alert-radio-label.sc-ion-alert-ios {
  color: var(--ion-color-primary);
}

.swiper .swiper-pagination-bullet {
  background: #2c2525;
}

.swiper .swiper-pagination-bullet-active {
  background: var(--ion-color-primary);
}

.swiper .swiper-slide {
  display: block;
}

// .swiper-slide {
//     visibility: hidden;
// }

.swiper-slide.swiper-slide-active {
  visibility: visible;
}

.login-form .continue-btn {
  text-align: center;
  margin: 15px auto 20px;

  ion-button {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    width: 239px;
    height: 45px;
    --background: #3861ad;
    --border-radius: 10px;
    text-transform: capitalize;
  }
}

.loader {
  --spinner-color: var(--ion-color-primary);
}

a {
  text-decoration: none;
}

ion-accordion.accordion-expanding h6,
ion-accordion.accordion-expanded ion-item[slot="header"] {
  color: var(--ion-color-primary);
}

ion-accordion.accordion-collapsed ion-item[slot="header"] {
  color: #000000;
}

.custom-class {
  text-transform: none !important;
}

//ion-modal
ion-modal {
  --height: 100%;
  --width: 100%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal::part(backdrop) {
  background: rgb(19 20 20);
  opacity: 1;
}

ion-modal ion-toolbar {
  --background: rgb(14 116 144);
  --color: white;
}

@media (min-width: 1100px) {
  ion-modal {
    --height: 100%;
  }
}

#fc_frame {
  position: absolute;
  top: 10px !important;
  right: 15px !important;
}

.mat-calendar-body-selected {
  background-color: var(--ion-color-danger) !important;
}

.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #ffffff !important;
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #ff4961;
  background: linear-gradient(#e21c21 0%, #ff4961 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  right: -21px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #ff4961;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #ff4961;
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #ff4961;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #ff4961;
}

ion-modal#celebration-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

#celebration-modal {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("/assets/discount/celebrations.gif");

    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
    }

    h2 {
      color: var(--ion-color-primary);
      font-weight: 500;
      text-transform: uppercase;
      font-size: 32px;
    }

    p {
      font-size: 18px;
    }

    ion-button {
      margin-bottom: 1rem;
    }
  }
}

.positionRelative {
  position: relative;
}

.currencyWrapper {
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 11;

  .currency {
    background: var(--ion-color-danger);
    color: #fff;
    width: 48px;
    height: 32px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    .fa-caret-down {
      font-size: 12px;
      margin-left: 7px;
    }
  }
}

.packCurrencyWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .currency {
    background: var(--ion-color-danger);
    color: #fff;
    width: 48px;
    height: 32px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    .fa-caret-down {
      font-size: 12px;
      margin-left: 7px;
    }
  }
}

.currencyBtn {
  .action-sheet-button-inner {
    justify-content: start;
  }

  .action-sheet-icon {
    color: var(--ion-color-danger);
    font-size: 2.5rem !important;
  }
}

.org_logo {
  border-radius: 50%;
  margin-left: 20px;
  width: 25%;
}
ion-icon{
  color: var(--ion-color-primary);
}
ion-card{
  box-shadow: 2px 2px 10px rgba(var(--ion-color-primary-rgb), 0.3);
  border: 1px solid rgba(var(--ion-color-primary-rgb), 0.3);
}
.text-primary{
  color: var(--ion-color-primary);
}
.bg-primary{
  background-color: var(--ion-color-primary);
  color: #fff;
}
ng-multiselect-dropdown {
  .dropdown-btn {
    span {
      color: #636c72;
    }
  }
  &.options-dropdown{
    .dropdown-list {
      top: auto;
      bottom: 100%;
    }
  }
}


input[type=file] {
  padding: 0;
}

input::file-selector-button {
  border: none;
  border-right: 1px solid #d8d6de;
  border-radius: 3px;
  height: 32px;
  background-color: transparent;
  font-size: .8rem;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
  color: #ff4961 !important;
  border: 2px solid #ff4961 !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
  background: #ff4961 !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid #ff4961 !important;
  background: #ff4961 !important;
}

ng-multiselect-dropdown .dropdown-btn span {
  color: #ffffff !important;

  &:nth-child(5) {
    span {
      color: black !important;
    }

  }
}
.card{
      border-radius: 20px;
}
.text-capitalize{
  text-transform: capitalize;
}
.text-bold{
  font-weight: 700;
}
.cursor-pointer{
  cursor: pointer;
}
.notification-no{
  font-size: 10px;
  top: 15px;
  position: absolute;
  right: 25px;
}
.bell-icon{
  color: #ffc107;
  position: relative;
  margin-right: 10px;
  margin-top: 6px;
  font-size: 33px;
  &:hover{
    cursor: pointer;
  }
}
.safe-area{
  .notification-no{
      font-size: 10px;
      top: 12px;
      position: absolute;
      right: 26px !important;
  }
}


/* Loader container for alignment */
.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.loader-container h4 {
  font-weight: 600 !important;
}

/* Grapes wrapper for centering */
.grapes-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 150px; /* Space for the jump */
  position: relative;
}

/* Grapes cluster */
.grapes {
  position: relative;
  width: 60px;
  height: 80px;
  animation: jump 1.5s infinite ease-in-out;
}

/* Individual grape */
.grape {
  width: 20px;
  height: 20px;
  background: radial-gradient(circle at 50% 35%, var(--ion-color-primary), var(--ion-color-gradient));
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Arrange grapes in a cluster */
.grape:nth-child(1) {
  top: 0;
  left: 20px;
}
.grape:nth-child(2) {
  top: 20px;
  left: 0;
}
.grape:nth-child(3) {
  top: 18px;
  left: 20px;
}
.grape:nth-child(4) {
  top: 20px;
  left: 40px;
}
.grape:nth-child(5) {
  top: 37px;
  left: 12px;
}
.grape:nth-child(6) {
  top: 37px;
  left: 29px;
}
.grape:nth-child(7) {
  top: 56px;
  left: 20px;
}
.grape:nth-child(8) {
  top: 3px;
  left: 4px;
}
.grape:nth-child(9) {
  top: 3px;
  left: 34px;
}

/* Leaf on the grapes */
.leaf {
  width: 30px;
  height: 15px;
  background-color: #4caf50;
  position: absolute;
  top: -15px;
  left: 15px;
  border-radius: 50%;
  transform: rotate(-30deg);
}

/* Stem of the grapes */
.stem {
  width: 5px;
  height: 15px;
  background-color: #6d4c41;
  position: absolute;
  top: -25px;
  left: 27px;
  border-radius: 2px;
}

/* Jump animation */
@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px); /* Adjust jump height */
  }
}

/* Shadow effect for realism */
.grapes::after {
  content: '';
  width: 60px;
  height: 10px;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: -10px;
  left: 0;
  border-radius: 50%;
  filter: blur(3px);
  animation: shadow 1.5s infinite ease-in-out;
}

/* Shadow animation */
@keyframes shadow {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.5;
  }
}
.text-capital{
  text-transform: capitalize;
}