// Nutrients Font Colors
$energy-color: #eb1c25;
$fibre-color: #ff7f25;
$protein-color: #a349a0;
$fat-color: #22b14d;
$carbs-color: #03a2e8;

:root {
  --ion-color-white: #fff;
  --ion-color-transparent: transparent;
  --ion-color-white-dark: #f3f5f7;
  --ion-color-white-light: #ece8e8;
  --ion-color-black: #212121;
  --ion-color-primary: #7c1aab;
  --ion-color-primary-rgb: 124, 26, 171;
  --ion-color-gradient: #3f0f7d;
  --ion-color-gradient-rgb: rgb(63, 15, 125);
  --ion-color-secondary: #000000;
  --ion-color-theme: #ede6f8;
  --ion-color-tertiary: #a569bd;
  --ion-text-secondary: #000000;
  --ion-color-red: #df212d;
  --ion-font-family: Poppins;
  --secondary-theme-color: #a569bd;
}

// Purple Theme
.purple {
  --ion-color-primary: #7c1aab;
  --ion-color-primary-rgb: 124, 26, 171;
  --ion-color-gradient: #3f0f7d;
  --ion-color-gradient-rgb: 63, 15, 125;
  --ion-color-theme: #ede6f8;
  --secondary-theme-color: #a569bd;
  ion-content {
    --background: var(--ion-color-theme) !important;
  }

  ion-button {
    --background: linear-gradient(to right, #3f0f7d, #7c1aab) !important;
    color: #fff;
    --border-radius: 12px !important;
    font-family: var(--ion-font-family);
  }
}


// Green Theme
.green {
  --ion-color-primary: #218838;
  --ion-color-primary-rgb: 33, 136, 56;
  --ion-color-gradient: #1d7730;
  --ion-color-gradient-rgb: 29, 119, 48;
  --ion-color-theme: #B5DDA7;
  --secondary-theme-color: #c5f1b5;
  ion-content {
    --background: var(--ion-color-theme) !important;
  }

  ion-button {
    --background: linear-gradient(to right, var(--ion-color-gradient), var(--ion-color-primary)) !important;
    color: #fff;
    --border-radius: 12px !important;
    font-family: var(--ion-font-family);
  }
}


// Red Theme
.default {
  --ion-color-primary: #E21C21;
  --ion-color-primary-rgb: 226, 28, 33;
  --ion-color-gradient: #f07272;
  --ion-color-gradient-rgb: 240, 114, 114;
  --ion-color-theme: #fff;
  --secondary-theme-color: #fff;
  ion-content {
    --background: var(--ion-color-theme) !important;
  }

  ion-button {
    --background: linear-gradient(to right, var(--ion-color-gradient), var(--ion-color-primary)) !important;
    color: #fff;
    --border-radius: 12px !important;
    font-family: var(--ion-font-family);
  }
}


// Yellow Theme
.yellow {
  --ion-color-gradient: #ffcc57;
  --ion-color-gradient-rgb: 255, 204, 87;
  --ion-color-primary: #d3ab3c;
  --ion-color-primary-rgb: 211, 171, 60;
  --ion-color-theme: #fff;
  --secondary-theme-color: #fff;
  ion-content {
    --background: var(--ion-color-theme) !important;
  }

  ion-button {
    --background: linear-gradient(to right, var(--ion-color-gradient), var(--ion-color-primary)) !important;
    color: #fff;
    --border-radius: 12px !important;
    font-family: var(--ion-font-family);
  }
}

// Blue Theme
.blue {
  --ion-color-gradient: #087b8d;
  --ion-color-gradient-rgb: 8, 123, 141;
  --ion-color-primary: #0098B0;
  --ion-color-primary-rgb: 0, 152, 176;
  --ion-color-theme: #e0fbff;
  --secondary-theme-color: #e0fbff;
  ion-content {
    --background: var(--ion-color-theme) !important;
  }

  ion-button {
    --background: linear-gradient(to right, var(--ion-color-gradient), var(--ion-color-primary)) !important;
    color: #fff;
    --border-radius: 12px !important;
    font-family: var(--ion-font-family);
  }
}

// Maroon Theme
.maroon {
  --ion-color-gradient: #ba0d0d;
  --ion-color-gradient-rgb: 186, 13, 13;
  --ion-color-primary: #800000;
  --ion-color-primary-rgb: 128, 0, 0;
  --ion-color-theme: #FFF6F6;
  --secondary-theme-color: #FFF6F6;
  ion-content {
    --background: var(--ion-color-theme) !important;
  }

  ion-button {
    --background: linear-gradient(to right, var(--ion-color-gradient), var(--ion-color-primary)) !important;
    color: #fff;
    --border-radius: 12px !important;
    font-family: var(--ion-font-family);
  }
}
